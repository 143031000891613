var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}],staticClass:"align-items-center auth-bg px-2 p-lg-2",attrs:{"lg":"12"}},[_c('div',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_c('h4',[_vm._v("Kindly fill the form below to register a client")])]),_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"circle","step-size":"xs","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Company Information","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name of Organization","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name of Organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Example Company Ltd."},model:{value:(_vm.form.organization_name),callback:function ($$v) {_vm.$set(_vm.form, "organization_name", $$v)},expression:"form.organization_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Contact Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Contact Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"example@domain.com"},model:{value:(_vm.form.contact_email),callback:function ($$v) {_vm.$set(_vm.form, "contact_email", $$v)},expression:"form.contact_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Contact Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Contact Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"08012345678"},model:{value:(_vm.form.contact_phone),callback:function ($$v) {_vm.$set(_vm.form, "contact_phone", $$v)},expression:"form.contact_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Contact Address","label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","placeholder":"123 Tom Dick and Harry Close, Lagos, Nigeria"},model:{value:(_vm.form.contact_address),callback:function ($$v) {_vm.$set(_vm.form, "contact_address", $$v)},expression:"form.contact_address"}})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"User Information","before-change":_vm.validationFormAdministrator}},[_c('validation-observer',{ref:"administratorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Enter First Name"},model:{value:(_vm.form.admin_first_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_first_name", $$v)},expression:"form.admin_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Enter Last Name"},model:{value:(_vm.form.admin_last_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_last_name", $$v)},expression:"form.admin_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Valid Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Enter Email Address","state":errors.length > 0 ? false:null},model:{value:(_vm.form.admin_email),callback:function ($$v) {_vm.$set(_vm.form, "admin_email", $$v)},expression:"form.admin_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-primary"},[_c('strong',[_vm._v("On successful registration a notification will be sent to this e-mail")])])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter Phone Number"},model:{value:(_vm.form.admin_phone),callback:function ($$v) {_vm.$set(_vm.form, "admin_phone", $$v)},expression:"form.admin_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation","rules":"integer:min:11|integer:max:11"}},[_c('b-form-input',{attrs:{"id":"phone2","placeholder":"Your designation at work"},model:{value:(_vm.form.designation),callback:function ($$v) {_vm.$set(_vm.form, "designation", $$v)},expression:"form.designation"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }